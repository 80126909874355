











































































































































import { Component, Vue } from "vue-property-decorator";
import {
  apiAdminList,
  apiAdminOtherList,
  apiAdminDelete,
  apiRoleList,
  apiAdminEdit,
  apiSupplierlists,
  apiShoplists,
  apiAdminOtherdelete,
} from "@/api/setting/permissions";
import { PageMode } from "@/utils/type";
import { RequestPaging } from "@/utils/util";
import LsDialog from "@/components/ls-dialog.vue";
import LsPagination from "@/components/ls-pagination.vue";
import { AdminList_Req } from "@/api/setting/permissions.d.ts";
import ExportData from "@/components/export-data/index.vue";

@Component({
  components: {
    LsDialog,
    LsPagination,
    ExportData,
  },
})
export default class Admin extends Vue {
  /** S Data **/
  apiAdminList = apiAdminList;
  // 表单数据
  form: AdminList_Req = {
    account: "", //账号
    name: "", //名称
    role_id: undefined, //角色id
    phone: "", //手机号
  };
  pager: RequestPaging = new RequestPaging();
  roleList: Array<object> = []; // 角色列表
  api_type: any = "platform";
  /** E Data **/

  /** S Methods **/
  // 搜索角色
  search() {
    this.pager.page = 1;
    this.getAdminList(1);
  }

  // 重置搜索
  resetSearch() {
    Object.keys(this.form).map((key) => {
      this.$set(this.form, key, "");
    });
    this.getAdminList(1);
  }

  // 获取管理员列表
  getAdminList(page?: number) {
    page && (this.pager.page = page);
    // 请求管理员列表
    if (this.api_type === "shop") {
      this.pager
        .request({
          callback: apiAdminList,
          params: {
            ...this.form,
            type: 1,
            sid: localStorage.getItem("shopId"),
          },
        })
        .catch(() => {});
    }
    if (this.api_type === "supplier") {
      this.pager
        .request({
          callback: apiAdminList,
          params: {
            ...this.form,
            sid: localStorage.getItem("supplierId"),
          },
        })
        .catch(() => {});
    }
    if (this.api_type === "platform") {
      this.pager
        .request({
          callback: apiAdminList,
          params: this.form,
        })
        .catch(() => {});
    }
  }

  // 获取角色列表
  getRoleList() {
    if (this.api_type === "shop") {
      apiShoplists({
        page_type: 1,
        sid: localStorage.getItem("shopId"),
      }).then((res) => {
        this.roleList = res.lists;
      });
    }
    if (this.api_type === "supplier") {
      apiSupplierlists({
        page_type: 1,
        sid: localStorage.getItem("supplierId"),
      }).then((res) => {
        this.roleList = res.lists;
      });
    }
    if (this.api_type === "platform") {
      apiRoleList({
        page_type: 1,
      }).then((res) => {
        this.roleList = res.lists;
      });
    }
  }

  // 添加管理员
  addAdmin() {
    if (this.$route.query.ccid) {
      this.$router.push({
        path: "/platform-permission/permissions/admin_edit",
        query: {
          mode: PageMode.ADD,
          ccid: this.$route.query.ccid,
          AppAuth: this.$route.query.AppAuth,
        },
      });
    } else {
      this.$router.push({
        path: "/setting/permissions/admin_edit",
        query: {
          mode: PageMode.ADD,
        },
      });
    }
  }

  // 删除管理员
  onAdminDelete(e: any) {
    if (this.api_type === "shop") {
      apiAdminOtherdelete({
        id: e.id,
        sid: localStorage.getItem("shopId"),
      }).then(() => {
        // 删除成功就请求新列表
        this.getAdminList(1);
        this.$message.success("删除成功!");
      });
    }
    if (this.api_type === "supplier") {
      apiAdminOtherdelete({
        id: e.id,
        sid: localStorage.getItem("supplierId"),
      }).then(() => {
        // 删除成功就请求新列表
        this.getAdminList(1);
        this.$message.success("删除成功!");
      });
    }
    if (this.api_type === "platform") {
      apiAdminDelete({ id: e.id }).then(() => {
        // 删除成功就请求新列表
        this.getAdminList(1);
        this.$message.success("删除成功!");
      });
    }
  }

  // 编辑管理员
  goAdminEdit(item: any) {
    const isAdd: any = false;
    if (this.$route.query.ccid) {
      this.$router.push({
        path: "/platform-permission/permissions/admin_edit",
        query: {
          mode: PageMode.EDIT,
          id: item.id,
          ccid: this.$route.query.ccid,
          AppAuth: this.$route.query.AppAuth,
        },
      });
    } else {
      this.$router.push({
        path: "/setting/permissions/admin_edit",
        query: {
          mode: PageMode.EDIT,
          id: item.id,
        },
      });
    }
  }

  // 更改管理员开关
  changeDisableSwitchStatus(value: 0 | 1, data: any) {
    apiAdminEdit({
      id: data.id,
      account: data.account,
      name: data.name,
      role_id: data.role_id,
      disable: data.disable,
      multipoint_login: data.multipoint_login,
      phone: data.phone,
    }).finally(() => {
      this.getAdminList(1);
    });
  }

  /** E Methods **/

  /** S Life Cycle **/
  created() {
    this.api_type = localStorage.getItem("api_type");
    if (this.$route.path == "/setting/permissions/admin") {
      this.api_type = "platform";
      localStorage.setItem("api_type", "platform");
      localStorage.removeItem("supplierId");
      localStorage.removeItem("shopId");
    }
    this.getRoleList();
    this.getAdminList(1);
  }

  /** E Life Cycle **/
}
